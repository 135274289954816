import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";

const SlideLogoForm = ({ image, text, sloganPrincipal, title }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div className='bgCountent relative w-full h-auto' style={{ backgroundImage: `url("${image ? image : rpdata?.stock?.[1]}")` }}>
            <div className='relative w-full h-auto'>
                <div className='w-4/5 mx-auto flex flex-col md:flex-row justify-center text-center z-50 space-y-4'>
                    <div className="w-[80%] md:w-1/2 flex justify-center mx-auto">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt="CompanyLogo"
                            className="w-full h-full object-contain flex"
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                        />
                    </div>
                    <div className='md:w-1/2 w-full text-white text-center flex flex-col items-center justify-center'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h5 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h5>
                                    : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h5 className='pb-3 capitalize font-extrabold text-[50px]'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h5>
                        }
                        <img src={rpdata?.stock?.[0]} alt="not found" className="w-[80%] rounded-sm" width={'250px'} />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SlideLogoForm;
